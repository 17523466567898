<template>
  <div :class="theme">
    <div style="padding: 20px">
      <p>模型树隐私保护指引</p>
      <p>Version 08</p>
      <p>更新日期：2023/12/17</p>
      <p>生效日期：2023/12/17</p>
      <p>
        您的个人信息安全对我们来说至关重要。一直以来，模型树都致力于为每位用户提供更安全的互联网环境。我们将依据《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》（GB/T
        35273-2017）以及其他相关法律法规和技术规范收集和使用您的个人信息，以帮助我们向您提供更优质的模型树产品和/或服务。《模型树隐私保护指引》（以下简称“本指引”），以更透明地呈现我们收集和使用您个人信息的情况，以及您享有的对个人信息的控制权。我们承诺会对您的个人信息和其它数据进行严格保密，并严格按照本指引所阐述的内容处理您的个人信息。我们会根据您的同意和其它可处理您个人信息的法律依据收集、使用、存储、共享和转移您的个人信息。
      </p>
      <p>以下是《模型树隐私保护指引》的要点说明：</p>
      <p>
        1.
        为帮助您注册并使用模型树产品或服务，我们可能收集与提供服务相关的个人信息，你有权拒绝或撤回授权；
      </p>
      <p>
        2.
        您可以访问、更正、删除您的个人信息，改变您授权同意的范围，注销您的账号，我们为您提供了行使这些权利的途径；
      </p>
      <p>
        3.
        我们采取了互联网业内标准的技术措施和数据安全措施来保护您的个人信息安全；
      </p>
      <p>
        4.
        除非再次征得您的同意，我们不会将您的个人信息用于本指引未载明的其他目的；
      </p>
      <p>5. 当您对本指引有任何疑问，可以与我们进一步联系与咨询；</p>
      <p>6. 请确认您为18岁以上人士。</p>
      <p>为了更好保护您的个人信息，建议您完整阅读《模型树隐私保护指引》。</p>
      <p>目录</p>
      <p>1. 引言；</p>
      <p>2. 我们处理个人信息的法律依据；</p>
      <p>3. 我们如何收集和使用您的个人信息；</p>
      <p>4. 我们如何共享、转让、公开披露您的个人信息；</p>
      <p>5. 我们如何保护您的个人信息；</p>
      <p>6. 您管理个人信息的权利；</p>
      <p>7. 我们发出的通知；</p>
      <p>8. 对个人信息的存储；</p>
      <p>9. 我们如何使用Cookie和其他追踪技术；</p>
      <p>10. 我们如何处理未成年人的个人信息；</p>
      <p>11. 如何更新本指引；</p>
      <p>12. 如何联系我们；</p>
      <p>13. 争议解决；</p>
      <p>14. 名词解释。</p>
      <p>1. 引言</p>
      <p>
        本指引适用于提供模型树内容创作平台（http://www.moxingshu.com.cn）产品和服务（统称为「模型树」）的逑知（深圳）教育科技有限公司及其关联公司（以下简称「我们」）。我们的地址是：广东省深圳市南山区粤海街道高新区社区高新南七道018号高新工业村R3-B栋01层211。
      </p>
      <p>【特别提示】</p>
      <p>
        请您在使用我们的各项产品和/或服务前，仔细阅读并充分理解本指引。<b
          >重点内容我们已采用粗体表示，希望您特别关注。一旦您使用或继续使用模型树产品/服务，即表示您同意我们按照本指引处理您的相关信息。如对本指引有任何疑问，您可以通过模型树官方网站（http://www.moxingshu.com.cn）上以及本指引中「关于我们」一节提供的联系方式与我们联系。</b
        >
      </p>
      <p>
        为了便于您理解本指引中的相关概念，我们对专有名词标记了下划线并在本指引第14节「名词解释」中给予详细介绍。本指引中相关名词的右上角用“*“标记的，代表涉及模型树收集的敏感信息，我们向您加以增强式提示。
      </p>
      <p>
        我们可能会不时对《模型树隐私保护指引》进行修订。当《模型树隐私保护指引》发生变更时，我们会在版本更新后以推送通知、弹窗等形式向您展示变更后的内容。
      </p>
      <p>
        请您注意，只有在您确认同意变更后，我们才会按照更新后的《互联文档隐私保护指引》收集、使用、处理和存储您的个人信息；您可以选择不同意，我们向您提供以「游客」身份进行浏览的功能，但这可能将导致您无法完整使用模型树的服务和功能。
      </p>
      <p>2. 模型树处理个人信息的法律依据</p>
      <p>
        若您是中华人民共和国大陆地区的用户，我们将依据《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》（GB/T
        35273-2017）以及其他相关法律法规收集和使用您的个人信息，为您提供模型树产品和/或服务。我们通常只会在征得您同意的情况下收集您的个人信息。在某些情况下，我们可能还会基于法律义务或者履行合同之必需向您收集个人信息，或者可能需要个人信息来保护您的重要利益或其他人的利益。
      </p>
      <p>3. 我们如何收集和使用您的个人信息</p>
      <p>
        我们会遵循正当、合法、必要的原则，出于本指引所述的以下目的，收集和使用您在使用服务过程中主动提供或因使用模型树产品和/或服务而产生的个人信息。如果我们要将您的个人信息用于本指引未载明的其它用途，或基于特定目的将收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
      </p>
      <p>3.1 帮助您成为我们的在线用户</p>
      <p>注册帐号：</p>
      <p>
        1.
        您注册并登录模型树帐号时，需要向我们提供以下信息：<b>帐号名称、头像（如有）和手机号码（用于实名认证）。</b>提供上述信息并同意《模型树写作服务使用协议》和本指引后，您可以使用模型树的核心业务功能，包括：浏览模型树平台内的内容，发布信息（包括文章、图片等）。
        如果您选择不提供为实现模型树核心业务功能的必备信息或使用其中某类功能的必备信息，将导致我们无法为您提供该功能。
      </p>
      <p>
        2.
        在您注册模型树帐号时，我们会需要您提供<b>手机号码</b>进行实名认证，如您拒绝提供<b>手机号码</b>进行实名验证，将导致注册不成功，因此无法使用发布信息的相关功能。但是您可以退出注册页面后进入“仅浏览”页面进行浏览。
      </p>
      <p>3.2 向您提供产品和/或服务</p>
      <p>A. 向您提供模型树产品和/或服务的核心业务功能</p>
      <p>
        为实现模型树的核心业务功能，我们可能需要向您收集个人信息。以下将详细列出模型树的核心业务功能及为实现该功能所需收集的个人信息，若您拒绝收集，则无法使用该服务。
      </p>
      <p>
        1.
        浏览、关注与收藏功能。您可浏览的内容包括文章、图片等；在浏览的过程中，您还可以关注您感兴趣的话题、内容、图片，并收藏上述内容。为此，我们可能会收集您使用模型树时的<b>设备信息，如设备型号、唯一设备标识符、操作系统、分辨率、电信运营商、软件应用列表等软硬件信息。</b>我们还可能收集您的浏览器类型，以此来为您提供信息展示的最优方案。此外，在您使用浏览和收藏功能的过程中，我们会自动收集您使用模型树的详细情况，并作为有关的网络日志保存，包括但不限于您输入的搜索关键词信息和点击的链接，您浏览和发布的内容及评论信息，您上传的图片信息、您的交易信息、您使用的语言、访问的日期和时间、及您请求的网页记录、操作系统、软件版本号、登录
        IP
        信息。在此过程中，我们会收集您的浏览记录，浏览记录包括您浏览的主页、文章、图片，您可以自主删除浏览记录。
      </p>
      <p>
        2. 内容发布功能。
        您注册成为模型树用户后，可在模型树平台上发布内容，此过程中，我们可能会收集您的<b
          >设备信息、浏览器类型、日志信息。</b
        >
      </p>
      <p>B．向您提供模型树产品和/或服务的附加业务功能</p>
      <p>
        为了向您提供更优质的产品和服务，我们可能需要收集下述信息。如果您拒绝提供下述信息，不影响您正常使用
        。
      </p>
      <p>
        3.2
        A项所描述的模型树核心业务功能，但我们无法向您提供某些特定功能和服务。
      </p>
      <p>
        1.
        搜索功能。当您使用模型树提供的搜索功能时，我们会收集您查询的关键字信息以及您在使用模型树服务时所阅览或要求的其他信息和内容详情。为了给您带来更便捷的搜索服务并不断完善模型树和/或服务，我们可能会使用浏览器网络存储机制（包括
        HTML5
        ）和应用数据缓存，收集您设备上的信息并进行本地存储。该等关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本指引的限制范围内。只有当您的搜索关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本指引对其进行处理与保护。
      </p>
      <p>
        2.
        客户服务。当您向模型树提起投诉、申诉或进行咨询时，为了方便与您联系或帮助您解决问题，我们可能需要您提供<b>姓名、手机号码、电子邮件及其他联系方式等个人信息。</b>如您拒绝提供上述信息，我们可能无法向您及时反馈投诉、申诉或咨询结果。
      </p>
      <p>
        3.
        支付功能。您可在模型树购买会员服务，进行付费咨询。在您使用该功能的过程中可能会需要进行支付，在支付过程中，我们可能会收集您的第三方支付帐号(例如支付宝帐号、Apple
        Pay
        帐号或其他形式的银行卡信息)，如果您开通了指纹支付，我们还可能需要收集您的生物识别信息*用于付款验证。
      </p>
      <p>
        4.
        营销活动。我们将会不时举办线上的营销活动，包括邀请用户参加线上互动活动，或用户主动参与站内的有奖活动等。在此类营销活动中，我们可能需要用户提供身份信息*或收件信息，以便我们能够提供票务服务或寄送礼品。在涉及模型树向您支付费用的情况下，我们可能需要收集您的身份信息用于代缴税款。
      </p>
      <p>3.3 我们可能从第三方间接获取您的个人信息</p>
      <p>
        A.
        您使用第三方帐号（微信）登录模型树时，可选择授权模型树在符合相关法律法规要求的前提下读取并获得您在该第三方平台上登记、公布、记录的公开信息<b
          >（包括昵称、头像）。</b
        >
      </p>
      <p>
        B.
        模型树需要您授权从第三方获取上述信息是为了记住您作为模型树用户的登录身份，以向您提供更优质的产品和/或服务。我们仅会在您的授权同意的范围内收集并使用您的个人信息。在您使用第三方帐号注册或登录后，您的第三方帐号将会与模型树帐号进行绑定，但您可以在【帐号与安全
        → 社交帐号绑定】中选择关闭，进行取消帐号绑定。
      </p>
      <p>3.4 敏感信息</p>
      <p>
        在向模型树提供任何属于敏感信息的个人信息前，请您清楚考虑该等提供是恰当的并且同意您的个人敏感信息可按本指引所述的目的和方式进行处理。
      </p>
      <p>
        <b
          >我们会在得到您的同意后收集和使用您的敏感信息以实现与模型树业务相关的功能，并允许您对这些敏感信息的收集与使用做出不同意的选择，但是拒绝使用这些信息会影响您使用相关功能。</b
        >
      </p>
      <p>3.5 征得授权同意的例外</p>
      <p>
        根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：
      </p>
      <p>a. 与国家安全、国防安全直接相关的；</p>
      <p>b. 与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>c. 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>
        d.
        出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </p>
      <p>e. 所收集的个人信息是您自行向社会公众公开的；</p>
      <p>
        f.
        从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；
      </p>
      <p>g. 根据您的要求签订和履行合同所必需的；</p>
      <p>
        h.
        用于维护模型树的产品和/或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
      </p>
      <p>i. 法律法规规定的其他情形。</p>
      <p>
        3.6
        如果您对我们收集和使用您的个人信息的法律依据有任何疑问或需要提供进一步的信息，请通过「如何联系我们」一节提供的联系方式与我们联系。
      </p>
      <p>4. 我们如何共享、转让、公开披露您的个人信息</p>
      <p>4.1 共享您的个人信息</p>
      <p>
        <b
          >A.
          我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外：</b
        >
      </p>
      <p>
        1.
        事先获得您的明确授权或同意：获得您的明确同意后，我们会与其他方共享您的个人信息；
      </p>
      <p>
        2.
        在法定情形下的共享：根据适用的法律法规、法律程序、政府的强制命令或司法裁定而需共享您的个人信息；
      </p>
      <p>
        3.
        在法律要求或允许的范围内，为了保护模型树及其用户或社会公众的利益、财产或安全免遭损害而有必要提供您的个人信息给第三方；
      </p>
      <p>
        4.
        与我们的关联公司共享：您的个人信息可能会在我们的关联公司之间共享。我们只会共享必要的个人信息，且这种共享受本指引所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意；
      </p>
      <p>
        5.
        与授权合作伙伴共享：为了向您提供更完善、优质的产品和服务，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，模型树向您寄送礼品时，模型树需要与物流服务提供商共享您的个人信息才能安排寄送。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。同时，我们会与合作伙伴签署严格的保密协定，要求他们按照我们的说明、本指引以及其他任何相关的保密和安全措施来处理您的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。如果您拒绝我们的合作伙伴在提供服务时收集为提供服务所必须的个人信息，将可能导致您无法在模型树中使用该第三方服务。
      </p>
      <p>
        B.
        就上述目的而言，在本「共享您的个人信息」一节提及的任何第三方所在的位置或使用您的个人信息的位置可能会在您的司法管辖区以外的地区，包括可能没有与您所在司法管辖区的法律实质上相似或达到该等法律目的的个人信息保护法规的地区；
      </p>
      <p>
        C.有关共享信息的提示：通过由我们或第三方提供的功能，您可以主动公开分享、共享信息（如向其他互联网平台转发内容），在这种情况下共享信息将被广泛并即时传送。
      </p>
      <p>
        但只要您不删除被共享的信息，该信息会一直留存在公众领域；在一些情况下，即使您删除共享信息，该等信息仍可由其他用户或与我们没有关联及不受我们控制的第三方独立地缓存、复制或储存，或通过其他用户或该等第三方在公众领域保存。例如，当您将您在模型树发表的回答转发到其他互联网平台，该平台将可能独立的展示、缓存或保存您转发的内容，其他互联网用户也可能转发、保存您的回答，而相应的页面将不受模型树的控制。
      </p>
      <p>4.2. 转让</p>
      <p>
        <b
          >除非获取您的明确同意，我们不会将您的个人信息转让给任何公司、组织或个人。</b
        >
      </p>
      <p>
        如果发生合并、收购或破产清算，将可能涉及到个人信息转让，此种情况下我们会要求新的持有您个人信息的公司、组织继续受本指引的约束。如果本指引中约定的个人信息的收集、处理方式发生任何改变，该公司、组织将重新向您征求授权同意。
      </p>
      <p>4.3. 公开披露</p>
      <p><b>除非获取您的明确同意，我们不会公开披露您的个人信息。</b></p>
      <p>
        基于法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会向有权机关披露您的个人信息。但我们保证，在上述情况发生时，我们会要求披露请求方必须出具与之相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施。
      </p>
      <p><b>4.4. 共享、转让、公开披露个人信息授权同意的例外</b></p>
      <p>
        <b
          >根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下共享、转让、公开披露您的个人信息：</b
        >
      </p>
      <p>A. 与国家安全、国防安全有关的；</p>
      <p>B. 与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>C. 与犯罪侦查、起诉、审判和判决执行等有关的；</p>
      <p>
        D.
        出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </p>
      <p>E. 您自行向社会公众公开的个人信息；</p>
      <p>
        F.
        从合法公开披露的信息中收集到的个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <p>G. 法律法规规定的其他情形。</p>
      <p>
        根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </p>
      <p>5. 我们如何保护您的个人信息</p>
      <p>
        您的个人信息安全对于我们至关重要。我们将严格遵守相关法律法规，采取业内认可的合理可行的措施，保护您的个人信息。防止信息遭到未经授权的访问、披露、使用、修改，避免信息损坏或丢失。
      </p>
      <p>5.1 技术措施与数据安全措施</p>
      <p>
        我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息安全。我们积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。
      </p>
      <p>
        我们通过与信息接触者签署保密协议、监控和审计机制来对数据进行全面安全控制。防止您的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。
      </p>
      <p>
        我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改，防止数据发生损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与模型树之间交换数据（如信用卡信息）时受
        SSL 加密保护；我们同时对本指引「引言」中出现的网站提供 https
        安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可以访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
      </p>
      <p>5.2 安全事件处置</p>
      <p>
        我们将尽力确保您发送给我们的任何信息的安全性，但请您理解，由于技术的限制以及在互联网行业可能存在的各种恶意手段，不可能始终保证信息百分之百的安全。您需要了解，您接入我们服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。为防止安全事故的发生，我们制定了妥善的预警机制和应急预案。若不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议和对您的补救措施，并立即启动应急预案，力求将损失最小化。我们将及时将事件相关情况以电话、推送通知等方式告知您，难以逐一告知用户时，我们会采取合理、有效的方式发布公告。
      </p>
      <p>
        同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况，紧密配合政府机关的工作。
      </p>
      <p>6. 您管理个人信息的权利</p>
      <p>
        我们非常重视您对个人信息的关注，并尽全力保护您对于自己个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
      </p>
      <p><b>6.1 访问和更正您的个人信息</b></p>
      <p>A. 除法律法规规定外，您有权随时访问和更正您的个人信息</p>
      <p>
        B.
        如果您需要查阅您在使用模型树过程中产生的其他个人信息，在合理要求下，我们会向您提供。若您无法通过上述方式访问或者更正您的个人信息的，也可以通过微信（微信号：siweimoxing003）联系我们。我们会在
        30 日回复您的访问请求。
      </p>
      <p><b>6.2 删除您的个人信息</b></p>
      <p>A. 我们在模型树平台上提供以下方式帮助您删除您在模型树上发布的信息。</p>
      <p>您可通过【我的模型树】，删除您的文章。</p>
      <p>B. 在以下情形中，您可以向我们提出删除个人信息的请求：</p>
      <p>如果我们处理个人信息的行为违反法律法规；</p>
      <p>如果我们收集、使用您的个人信息，却未征得您的同意；</p>
      <p>如果我们处理个人信息的行为违反了与您的约定；</p>
      <p>如果您注销了模型树帐号；</p>
      <p>如果我们终止服务及运营。</p>
      <p>
        以上删除请求一旦被响应，我们还将同时通知从模型树获得您个人信息的第三方实体（例如使用模型树帐号作为第三方登录账号的其他互联网平台），要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </p>
      <p>
        如果您在删除过程中遇到任何问题，请通过微信客服（微信号：siweimoxing003）联系我们联系我们。我们会在
        30 日回复您的请求。
      </p>
      <p>6.3 改变您授权同意的范围</p>
      <p>
        每个业务功能需要一些基本的个人信息才能得以完成（见本指引第 3
        部分）。对于您个人信息的收集和使用，您可以随时通过以下方式给予或收回您的授权同意。
      </p>
      <p>6.4 注销您的帐号</p>
      <p>
        1、在App中，登录账号之后，点击我的页面，点击用户名称或修改按钮，进入我的账号页面，点击「注销账户」。
      </p>
      <p>
        <img
          src="https://moxingshu.oss-cn-shanghai.aliyuncs.com/image/3f3daaf7384640558ea2bfab8744ecf6.png"
          style="max-width: 200px; width: 100%"
        />
      </p>
      <p>
        2、如果您需要注销您的模型树帐号，请通过微信客服（微信号：siweimoxing003）联系我们，要求对您的模型树帐号进行注销。我们将在验证您的身份并清理帐号中的资产（包括已购买的服务、已充值的模型树费用等）后，为您提供帐号注销服务。
      </p>
      <p>
        为了向您提供更加便捷的注销方式，我们后续会不断优化我们的产品，并且通过页面公告向您告知。在注销帐号之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
      </p>
      <p>6.6 获取个人信息副本</p>
      <p>
        您有权获取您的个人信息副本，您可通过微信客服（微信号：siweimoxing003）联系我们，我们将在
        30 日内对您的请求进行处理。
      </p>
      <p>6.7响应您的上述请求</p>
      <p>
        为保障安全，我们可能会先要求您验证自己的身份，然后再处理您的请求。您可能需要提供书面请求，或以其他方式证明您的身份。对于您的请求，我们原则上将于
        30 日内做出答复。
      </p>
      <p>
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      </p>
      <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
      <p>1. 与国家安全、国防安全直接相关的；</p>
      <p>2. 与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>3. 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>4. 有充分证据表明您存在主观恶意或滥用权利的；</p>
      <p>5. 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
      <p>6. 涉及商业秘密的。</p>
      <p>7. 我们发出的通知</p>
      <p>7.1. 通知及其他类别的通讯</p>
      <p>您可通过【设置 → 推送通知设置】来选择是否接受我们的推送通知。</p>
      <p>7.2. 与服务有关的公告</p>
      <p>
        我们可在我们认为必需时（例如当我们暂停模型树服务进行维修时）不时向您发出一些与服务有关的公告。您不可取消这些与服务有关、性质不属于推广的公告。
      </p>
      <p>8. 您个人信息的存储</p>
      <p>
        我们将从中华人民共和国境内获得的信息存放于中华人民共和国境内。如果发生数据的跨境传输，我们会单独向您以弹窗或邮件的方式告知您数据出境的目的、接收方等，并征得您的授权同意，我们会确保数据接收方有充足的数据保护能力来保护您的个人信息。
      </p>
      <p>
        我们承诺您个人信息的存储时间始终处于合理必要期限内。在仅浏览功能下所收集的个人信息如浏览记录、IP
        信息，我们的存储期限不会超过一个月。对于超出期限的个人信息，我们会立即删除或做匿名化处理。
      </p>
      <p>
        如我们因经营不善或其他原因出现停止运营的情况，我们会立即停止对您个人信息的收集，删除已收集的个人信息。我们会将此情况在网站上进行公告或以站内信、邮件等其他合理方式逐一传达到各个用户。
      </p>
      <p>
        当您使用部分单项服务时，可能需要您在您的设备中开启特定的访问权限，以实现这些权限所涉及信息的收集和使用。该等单项服务包括但不限于：
      </p>
      <p>
        如我们访问您的位置信息，是为了向您提供更契合您需求的页面展示、实现基于GPS定位的功能，并使您可以在Android
        10、11系统上通过蓝牙匹配、链接智能硬件设备；
      </p>
      <p>
        如我们访问您的<b>相机</b>，是为了使您可以使用<b>相机</b>进行扫码、拍摄，用于实现登录、支付、上传图片、使用文字识别服务功能；
      </p>
      <p>
        如我们访问您的相册，是为了使您可以实现您设备中的照片、图片或视频的取用与上传，便于您进行更换头像、在笔记中插入图片、使用图片生成笔记、使用文字识别服务功能；
      </p>
      <p>
        如我们访问您的麦克风，是为了使您可以使用语音转写、实时翻译功能，我们会收集您的录音内容以识别您的具体需求；
      </p>
      <p>
        如我们访问您的App安装列表，是为了使您可以使用支付和分享功能并获得良好体验；
      </p>
      <p>
        如我们访问您的剪贴板，是为了使您可以使用剪藏和智能剪藏功能。您的剪贴板信息将仅在设备本地被处理，不会被用于其他用途；
      </p>
      <p>
        在您开启通讯录权限后，我们可以获取您的通讯录好友信息，以便将您提供的通讯录好友信息以名片形式导入产品进行存储；
      </p>
      <p>
        在您允许我们开启存储权限后，我们可以获取您的照片、媒体内容和文件等信息，这是为了方便您随时分享、存储您的笔记信息；
      </p>
      <p>
        在您允许我们开启蓝牙权限后，通过蓝牙将模型树笔记相关智能硬件与模型树笔记App连接，使您的数据可以在模型树笔记App上更新与同步；
      </p>
      <p>
        在您使用模型树笔记服务中的特定识别功能时，我们将会收集、储存您上传的图片、文字、语音信息，我们的相关识别程序会对这些数据进行处理分析并返回对应的文字结果；
      </p>
      <p>
        我们还会采用一些技术来帮您最大限度地利用本服务，不断改进为您提供的服务，提升您的服务使用体验。我们的系统会自动分析您的数据，增强模型树笔记的一些特有功能，这些收集和分析行为是由系统自动进行的，模型树笔记的人员不会查看该等内容（但为了排除故障以及提供用户支持的情形除外）。我们不会以任何宣传为目的而收集、分析或向任何第三方披露该等内容。相关的功能和场景可能包括：
      </p>
      <p>
        “搜索”功能：当您在模型树笔记帐户中进行搜索时，便于您找到所需的信息。
      </p>
      <p>
        “推荐内容”功能：模型树笔记服务使用多种技术来向您显示相关内容。显示的内容可能包括来自您自己帐户的笔记，以及您选择接收的第三方内容。“推荐内容”绝不是广告内容。我们相信这些功能会提升您的服务使用体验。“推荐内容”在提供该功能的应用程序中默认为开启，但是您可以选择将其关闭。“
      </p>
      <p>
        通过检测您在模型树笔记帐户中的特定操作（例如：通过电子邮件发送内容到您的模型树笔记帐户）或与笔记相关的特定属性（例如：笔记内的文件是PDF还是图像文件，或您使用哪个应用程序来创建笔记），我们可能会为您推荐模型树笔记产品或者服务功能，帮助您从模型树笔记服务中收获更佳的体验。
      </p>
      <p>
        在特定的时间或地点，显示与您如何使用模型树笔记服务或者能够怎样使用模型树笔记服务的最相关的信息。
      </p>
      <p>根据您存储在模型树笔记帐户中的信息，为您提出相关的操作建议。</p>
      <p>
        当您需要关闭任何权限时，大多数移动设备都会支持您的需求，具体方法请参考或联系您移动设备的服务商或生产商。当您开启任一权限，即代表您授权我们可以收集和使用相关个人信息来为您提供相关服务；当您关闭任一权限，即代表您取消了相关授权，我们将不再基于该权限收集和使用您的相关个人信息，也不再为您提供该权限所对应的服务。请注意，您关闭权限的决定和行为，不会影响此前基于您的授权已经进行的个人信息收集及使用。
      </p>
      <p>9. 我们如何使用 Cookie 和其他追踪技术</p>
      <p>
        您使用我们的服务时，我们会在您的计算机或移动设备上存储名为 Cookie
        的小数据文件。Cookie
        通常包含标识符、站点名称以及一些号码和字符。我们使用该等信息判断注册用户是否已经登录，提升服务/产品质量及优化用户体验。如您不希望个人信息保存在
        Cookie 中，您可对浏览器进行配置，选择禁用 Cookie 功能。禁用 Cookie
        功能后，可能影响您访问模型树或不能充分取得模型树提供的服务。
      </p>
      <p>
        我们不会将 Cookie
        用于本指引所述目的之外的任何用途。您可根据自己的偏好管理或删除
        Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止
        Cookie 的功能。
      </p>
      <p>10. 我们如何处理未成年人的个人信息</p>
      <p>
        10.1 模型树服务主要面向成人。我们的产品、网站和服务主要面向成人。若您是
        18
        周岁以下的未成年人，在使用我们的产品和/或服务前，您应在监护人的陪同下阅读本指引，并应确保已征得您的监护人同意后使用我们的服务并向我们提供您的信息。我们会根据国家相关法律法规的规定着重保护未成年人的个人信息。
      </p>
      <p>
        10.2
        如您的监护人不同意您按照本指引使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们。
      </p>
      <p>
        10.3
        对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
      </p>
      <p>
        10.4
        若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您及时与我们联系。我们将根据国家相关法律法规及本指引的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
      </p>
      <p>11. 如何更新与修改本指引</p>
      <p>
        11.1
        本指引为《模型树协议（草案）》的重要组成部分。模型树保留不时更新或修改本指引的权利。
      </p>
      <p>
        11.2
        未经您明确同意，我们不会削减您按照本指引所应享有的权利。我们会通过平台端推送通知、弹窗形式等合理方式通知您，以便您能及时了解本指引所做的任何变更。
      </p>
      <p>
        11.3
        对于重大变更，视具体情况我们可能还会提供更为显著的通知，说明本指引的具体变更内容。重大变更包括但不限于：
      </p>
      <p>
        A.
        我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </p>
      <p>
        B.
        我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </p>
      <p>C. 个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>D. 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>E. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；</p>
      <p>F. 个人信息安全影响评估报告表明存在高风险时。</p>
      <p>
        11.4
        若您不同意修改后的隐私保护指引，您有权并应立即停止使用模型树的服务。如果您继续使用模型树的服务，则视为您接受模型树对本指引相关条款所做的修改。
      </p>
      <p>12. 如何联系我们</p>
      <p>
        当您对本指引有任何疑问，您可以通过微信客服（微信号：siweimoxing003）联系我们，我们设立了个人信息保护专职部门，将及时解决您的问题。
      </p>
      <p>一般情况下，我们将在三十天内回复。</p>
      <p>13. 争议解决</p>
      <p>
        13.1.
        因本指引以及我们处理您个人信息事宜引起的任何争议，您可诉至深圳市南山区区人民法院。
      </p>
      <p>
        13.2
        如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。
      </p>
      <p>14. 名词解释</p>
      <p>本隐私保护指引中使用到的名词，在通常意义中有如下定义：</p>
      <p>
        个人信息：个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，包括但不限于自然人的姓名、出生日期、身份证件号码、个人生物识别信息、住址、电话号码等。
      </p>
      <p>
        个人敏感信息：个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。例如，个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信内容、健康生理信息等。
      </p>
      <p>
        设备：设备是指可用于访问模型树产品和/或服务的装置，例如台式计算机、笔记本电脑、平板电脑或智能手机。
      </p>
      <p>
        IP 地址：每台上网的设备都会指定一个编号，称为互联网协议 ( IP )
        地址。这些编号通常都是根据地理区域指定的。IP
        地址通常可用于识别设备连接至互联网时所在的位置。
      </p>
      <p>
        SSL： SSL（Secure Socket
        Layer）又称为安全套接层，是在传输通信协议（TCP/IP）上实现的一种安全协议。SSL
        支持各种类型的网络，同时提供三种基本的安全服务，均通过使用公开密钥和对称密钥技术以达到信息保密的效果。
      </p>
      <p>
        信息收集技术：我们在《模型树隐私保护指引》中所述的“自动收集”包括以下方式：
        A. Cookie（曲奇档案） Cookie
        是您浏览网页时，网站服务器放在客户端（您的计算机、移动电话或其他智能终端内）里面的一个小小的文本文件，当您再次访问相应网站时，网站就可通过
        Cookie 识别您的浏览器。Cookie
        可能会存储用户偏好及其他信息。您可以将浏览器配置为拒绝所有 Cookie
        或在网站发送 Cookie 时显示提示。不过，如果没有
        Cookie，某些网站功能或服务可能无法正常工作。 B. Web beacon（网络信标）
        Web beacon
        是装嵌在网站或电邮内的电子图像文件案或其他技术，可用于计算访客数目、记录您是否及何时阅览电邮或网站，或用以使用某些
        Cookie。 C. Log files（日志文件） Log files
        储存自动收集的若干数据。该等数据包括互联网协议（ IP
        ）地址、浏览器类型、互联网服务提供商（ ISP
        ）、引用/退出页面、操作系统、日期/时间戳和点击流数据等。 D. ET
        Tag（实体标签） ET Tag 是在互联网浏览器与互联网服务器之间背后传送的 HTTP
        协议标头，可代替
        Cookie，用以追踪个别使用者，使我们可更深入地了解和改善我们的服务。 E.
        JavaScript
        JavaScript是一种编程语言，用于制作更具互动性和动态的网页。JavaScript
        可以设定 Cookie、阅读 Cookie 及删除 Cookie。
      </p>
      <p>
        关联公司：指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被依法认定的方式。
      </p>
      <p>算法：计算机在执行解题运算时遵循的流程或一系列规则。</p>
      <p>
        应用数据缓存：应用数据缓存是指设备上的一种数据存储机制。使用它有很多好处，例如，可让网络应用在未连接互联网的情况下运行，以及可通过提高内容加载速度来改善相关应用的性能。
      </p>
      <p>
        使用浏览器进行网络存储：
        “使用浏览器进行网络存储”的机制可让网站将数据存储在设备上的浏览器中。如果在“本地存储”模式下使用该机制，则数据可存储在各个会话中。这样一来，即使在关闭并重新打开浏览器后，用户仍可以获取相应数据。有助于实现网络存储的技术之一就是
        HTML 5。
      </p>
      <p>
        帐户：只要注册帐号并向我们提供一些个人信息（本指引 3.1
        节已经说明），您就可以使用我们的服务。当您访问模型树时，系统会利用这些帐号信息对您进行身份验证，以防止未经授权的人员访问您的帐号。
      </p>
      <p>
        非个人身份信息：记录的与用户相关的信息，但实际上不可直接或间接识别您身份的信息，包括经过去标识化、匿名化处理或化名方式提供的个人信息。
      </p>
      <p>
        去标识化：指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。
      </p>
      <p>
        匿名化：指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
      </p>
      <p>
        服务器日志：与大多数网站一样，我们的服务器会自动记录您在访问网站时所发出的网页请求。这些“服务器日志”通常包括您的网络请求、互联网协议地址、浏览器类型、浏览器语言、请求的日期和时间及可以唯一识别您的浏览器的一个或多个
        Cookie。
      </p>
      <p><b>与第三方共享个人信息清单</b></p>
      <p><b>一、接入第三方SDK列表</b></p>
      <p>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="序号" />
          <el-table-column prop="name" label="sdk名称" />
          <el-table-column prop="scene" label="应用场景描述" />
          <el-table-column prop="info" label="个人信息字段" />
          <el-table-column prop="thirdParty" label="第三方机构名称" />
          <el-table-column prop="shared" label="共享方式" />
          <el-table-column label="隐私协议链接">
            <template #default="scope">
              <a :href="scope.row.url" target="_blank"
                >点击查看第三方隐私政策</a
              >
            </template>
          </el-table-column>
        </el-table>
      </p>
      <p><b>二、接入第三方api共享信息列表</b></p>
      <p>无</p>
      <p><b>三、关联APP列表</b></p>
      <p>无</p>
      <p></p>
      <p></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      theme: "light",
      tableData: [
        {
          id: 1,
          name: "微信开放平台SDK",
          scene: "微信帐号授权登录，分享到微信",
          info: "IMEI、Android_id、序列号、IMSI、位置信息、运营商信息、IP",
          thirdParty: "深圳市腾讯计算机系统有限公司",
          shared: "SDK本机采集",
          url: "https://www.tencent.com/zh-cn/privacy-policy.html",
        },
        {
          id: 2,
          name: "腾讯X5内核SDK",
          scene: "腾讯X5内核",
          info: "设备型号、设备id、网络状态，IMSI、应用列表信息、BSSID",
          thirdParty: "深圳市腾讯计算机系统有限公司",
          shared: "SDK本机采集",
          url: "https://www.tencent.com/zh-cn/privacy-policy.html",
        },
        {
          id: 3,
          name: "腾讯buglySDK",
          scene: "系统安全（崩溃捕捉）",
          info: "Android_id",
          thirdParty: "深圳市腾讯计算机系统有限公司",
          shared: "SDK本机采集",
          url: "https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf",
        },
        {
          id: 4,
          name: "友盟统计SDK",
          scene: "数据统计，分享",
          info: "ICCID、IMEI、MAC地址、Android_id、OPENUDID、GUID、SIM卡IMSI、网络状态，idfa，位置信息、电话状态、硬件序列号",
          thirdParty: "阿里巴巴集团控股有限公司",
          shared: "SDK本机采集",
          url: "https://www.umeng.com/page/policy",
        },
        {
          id: 5,
          name: "MobTeach",
          scene: "分享，第三方登录",
          info: "Android ID、OAID、GAID、设备信息、地理位置信息",
          thirdParty: "上海游昆信息技术有限公司",
          shared: "SDK本机采集",
          url: "https://www.mob.com/about/policy",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.params.theme == "light") {
      this.theme = "light";
    } else if (
      this.$route.params.theme == "dark" ||
      this.$root.config.theme.includes("dark")
    ) {
      this.theme = "dark";
    }
  },
};
</script>
<style scoped>
.dark {
  color: #ebebeb;
  background: #101218;
}
p b {
  font-size: 18px;
}
</style>
